import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Hero from "../components/hero";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";

const ContactUsPage = ({ data }) => {
	const { siteUrl } = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet>
			<GatsbySeo
				title="Contact Us | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Us | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>
			<section>
				<Hero
					subTitle="Home > Book an appointment"
					title="Book an appointment"
					backgroundImage={
						data.heroImg?.localFile.childImageSharp.gatsbyImageData
					}
				/>
			</section>
			<section className="pb-5 pb-lg-7">
				<Container>
					<Row>
						<Col className="position-relative">
							<div
								style={{ height: "350px" }}
								className="w-100 pt-5 pt-md-0 mb-7 mb-md-5 mb-xl-0"
							>
								<iframe
									className="position-absolute d-none d-xl-block"
									src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10016.857646080618!2d-0.0227096!3d51.122953!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x93d6e60475b038d9!2sThe%20Facial%20Rehabilitation%20Centre!5e0!3m2!1sen!2sae!4v1660581156452!5m2!1sen!2sae"
									style="border:0; width:100%; height:400px; top: -8rem;"
									allowfullscreen=""
									loading="lazy"
									referrerpolicy="no-referrer-when-downgrade"
								></iframe>
								<iframe
									className=" d-xl-none"
									src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10016.857646080618!2d-0.0227096!3d51.122953!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x93d6e60475b038d9!2sThe%20Facial%20Rehabilitation%20Centre!5e0!3m2!1sen!2sae!4v1660581156452!5m2!1sen!2sae"
									style="border:0; width:100%; height:350px; "
									allowfullscreen=""
									loading="lazy"
									referrerpolicy="no-referrer-when-downgrade"
								></iframe>
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col xl={9}>
							<Row>
								<Col className="text-center mb-5 mb-lg-0" lg={4}>
									<MdLocationOn className="display-2 text-grey" />
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10016.857646080618!2d-0.0227096!3d51.122953!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x93d6e60475b038d9!2sThe%20Facial%20Rehabilitation%20Centre!5e0!3m2!1sen!2sae!4v1660581156452!5m2!1sen!2sae"
										className="fs-5 d-block mt-4"
									>
										The Facial Rehabilitation Centre
									</a>
								</Col>
								<Col className="text-center mb-5 mb-lg-0" lg={4}>
									<MdPhone className="display-2 text-grey" />
									<a href="tel:+447813194142" className="fs-5 d-block mt-4">
										+44 781 319 4142
									</a>
								</Col>
								<Col className="text-center" lg={4}>
									<MdEmail className="display-2 text-grey" />
									<a
										href="mailto:info@facialrehabilitation.com"
										className="fs-5 d-block mt-4"
									>
										info@facialrehabilitation.com
									</a>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 py-lg-7 bg-light-grey">
				<Container>
					<Row className="justify-content-center">
						<Col xl={9}>
							<Row>
								<Col className="text-center mb-5">
									<h2 className="text-dark-bg pb-4">Contact us</h2>
									{/* <p>
										Duis aute irure dolor in reprehenderit in voluptate velit
										esse cillum dolore fugiat sunt culpa officia deserunt mollit
										anim est laborum.
									</p> */}
								</Col>
							</Row>
							<Row>
								<Col>
									<ContactForm />
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 pt-lg-7">
				<Container>
					<Row className="align-items-center">
						<Col className="text-center text-lg-start" lg={6}>
							<h2 className="text-dark-bg pb-4">
								Online consultations available
							</h2>
							<p>
								We are happy to arrange virtual consultations for all of our
								patients.
							</p>
						</Col>
						<Col lg={6}>
							<Row className="align-items-center">
								<Col className="py-5 py-lg-0 text-center" xs={6}>
									<GatsbyImage
										image={
											data.zoomImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.zoomImg.alt}
									/>
								</Col>
								<Col className="text-center" xs={6}>
									<GatsbyImage
										image={
											data.teamsImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.teamsImg.alt}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		heroImg: wpMediaItem(title: { eq: "Testimonial-Hero" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		zoomImg: wpMediaItem(title: { eq: "Contact-Us-zoom" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		teamsImg: wpMediaItem(title: { eq: "Contact-Us-teams" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
	}
`;

export default ContactUsPage;
