import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ContactForm() {
	const [state, handleSubmit] = useForm("maykwlkw");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Row className="">
						<Col>
							<Form.Group className="mb-4" controlId="name">
								<Form.Label className="fs-5">Full name</Form.Label>
								<Form.Control
									placeholder="First and second name"
									className="py-3"
									name="name"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row className="gx-6">
						<Col md={6}>
							<Form.Group className="mb-4" controlId="phone">
								<Form.Label className="fs-5">Phone</Form.Label>
								<Form.Control
									placeholder="Mobile or landline"
									className="py-3"
									name="phone"
									type="tel"
								/>
							</Form.Group>
							<ValidationError
								prefix="Phone"
								field="phone"
								errors={state.errors}
							/>
						</Col>

						<Col md={6}>
							<Form.Group className="mb-4" controlId="email">
								<Form.Label className="fs-5">Email</Form.Label>
								<Form.Control
									placeholder="E-mail address"
									className="py-3"
									name="_replyto"
									type="email"
								/>
							</Form.Group>
							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
						</Col>
					</Row>

					<Row className="gx-6">
						<Col md={6} lg={4}>
							<Form.Group className="mb-4" controlId="country">
								<Form.Label className="fs-5">Country</Form.Label>
								<Form.Control
									placeholder="Country"
									className="py-3"
									name="country"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="country"
								field="country"
								errors={state.errors}
							/>
						</Col>
						<Col md={6} lg={4}>
							<Form.Group className="mb-4" controlId="city">
								<Form.Label className="fs-5">City</Form.Label>
								<Form.Control
									placeholder="City"
									className="py-3"
									name="city"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="city"
								field="city"
								errors={state.errors}
							/>
						</Col>

						<Col md={12} lg={4}>
							<Form.Group className="mb-4" controlId="state_province_region">
								<Form.Label className="fs-5">State/Province/Region</Form.Label>
								<Form.Control
									required
									placeholder="State/Province/Region"
									className="py-3"
									name="state_province_region"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="state_province_region"
								field="state_province_region"
								errors={state.errors}
							/>
						</Col>
					</Row>

					<Form.Group className="mb-4" controlId="subject">
						<Form.Label className="fs-5">Subject</Form.Label>
						<Form.Select name="subject" className="py-3 ubuntu-regular text-p">
							<option className="ubuntu-regular text-p">Please select</option>
							<option className="ubuntu-regular text-p">Rehab</option>
							<option className="ubuntu-regular text-p">Injections</option>
						</Form.Select>
					</Form.Group>

					<ValidationError
						prefix="Subject"
						field="subject"
						errors={state.errors}
					/>

					<Form.Group className="mb-4" controlId="which_side_is_facial_palsy">
						<Form.Label className="fs-5">
							Which side is facial palsy?
						</Form.Label>
						<Form.Select
							name="which_side_is_facial_palsy"
							className="py-3 ubuntu-regular text-p"
						>
							<option className="ubuntu-regular text-p">Please select</option>
							<option className="ubuntu-regular text-p">Left</option>
							<option className="ubuntu-regular text-p">Right</option>
							<option className="ubuntu-regular text-p">Both</option>
						</Form.Select>
					</Form.Group>
					<Form.Group
						className="mb-4"
						controlId="type_of_appointment_preferred"
					>
						<Form.Label className="fs-5">
							Type of appointment preferred
						</Form.Label>
						<Form.Select
							name="type_of_appointment_preferred"
							className="py-3 ubuntu-regular text-p"
						>
							<option className="ubuntu-regular text-p">Please select</option>
							<option className="ubuntu-regular text-p">Face to face</option>
							<option className="ubuntu-regular text-p">Online</option>
						</Form.Select>
					</Form.Group>

					<ValidationError
						prefix="type_of_appointment_preferred"
						field="type_of_appointment_preferred"
						errors={state.errors}
					/>
					<ValidationError
						prefix="which_side_is_facial_palsy"
						field="which_side_is_facial_palsy"
						errors={state.errors}
					/>
					<Row className="gx-6">
						<Col className="mb-4" md={6}>
							<Form.Group controlId="date_of_onset">
								<Form.Label className="fs-5">Date of onset</Form.Label>
								<Form.Control
									className="py-3"
									type="date"
									name="date_of_onset"
								/>
							</Form.Group>

							<ValidationError
								prefix="date_of_onset"
								field="date_of_onset"
								errors={state.errors}
							/>
						</Col>

						<Col className="mb-4" md={6}>
							<Form.Group controlId="diagnosis_if_know">
								<Form.Label className="fs-5">Diagnosis if know</Form.Label>
								<Form.Control
									placeholder="Diagnosis (if known)"
									className="py-3"
									name="diagnosis_if_know"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="diagnosis_if_know"
								field="diagnosis_if_know"
								errors={state.errors}
							/>
						</Col>
					</Row>
					{/* <Row className="gx-6">
				<Col className="">
					<Form.Group className="mb-4" controlId="preferred_therapist">
						<Form.Label className="fs-5">Preferred therapist</Form.Label>
						<Form.Select
							name="preferred_therapist"
							className="py-3 ubuntu-regular text-p"
						>
							<option className="ubuntu-regular text-p">No preference</option>
							<option className="ubuntu-regular text-p">Tamsin Gwynn</option>
							<option className="ubuntu-regular text-p">Karen Young</option>
							<option className="ubuntu-regular text-p">
								Catriona Neville
							</option>
						</Form.Select>
					</Form.Group>

					<ValidationError
						prefix="preferred_therapist"
						field="preferred_therapist"
						errors={state.errors}
					/>
				</Col>
			</Row> */}
					<Form.Group className="mb-4" controlId="details-of-enquiry">
						<Form.Label className="fs-5">Details of enquiry</Form.Label>
						<Form.Control
							placeholder="Input details of enquiry in here"
							className="py-3"
							name="details-of-enquiry"
							as="textarea"
							rows={5}
						/>
					</Form.Group>
					<ValidationError
						prefix="details-of-enquiry"
						field="details-of-enquiry"
						errors={state.errors}
					/>
					{/* <div
				class="g-recaptcha"
				data-sitekey="6LcauP0gAAAAAMl9A0wI1H7n2ZyRHqx2MpLoxl5Y"
			></div> */}

					<div className="text-end">
						<Button
							style={{ borderRadius: "0px" }}
							className="btn western w-100  white-link-blue px-5 mt-4 mt-md-0  py-2 "
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Send message
						</Button>
					</div>
					<p className="mt-4">We endeavour to reply within 48 hours.</p>
					<p>Please make sure to check your junk mail for our reply.</p>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Row className="">
				<Col>
					<Form.Group className="mb-4" controlId="name">
						<Form.Label className="fs-5">Full name</Form.Label>
						<Form.Control
							placeholder="First and second name"
							className="py-3"
							name="name"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
			</Row>
			<Row className="gx-6">
				<Col md={6}>
					<Form.Group className="mb-4" controlId="phone">
						<Form.Label className="fs-5">Phone</Form.Label>
						<Form.Control
							placeholder="Mobile or landline"
							className="py-3"
							name="phone"
							type="tel"
						/>
					</Form.Group>
					<ValidationError prefix="Phone" field="phone" errors={state.errors} />
				</Col>

				<Col md={6}>
					<Form.Group className="mb-4" controlId="email">
						<Form.Label className="fs-5">Email</Form.Label>
						<Form.Control
							placeholder="E-mail address"
							className="py-3"
							name="_replyto"
							type="email"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />
				</Col>
			</Row>

			<Row className="gx-6">
				<Col md={6} lg={4}>
					<Form.Group className="mb-4" controlId="country">
						<Form.Label className="fs-5">Country</Form.Label>
						<Form.Control
							placeholder="Country"
							className="py-3"
							name="country"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="country"
						field="country"
						errors={state.errors}
					/>
				</Col>
				<Col md={6} lg={4}>
					<Form.Group className="mb-4" controlId="city">
						<Form.Label className="fs-5">City</Form.Label>
						<Form.Control
							placeholder="City"
							className="py-3"
							name="city"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="city" field="city" errors={state.errors} />
				</Col>

				<Col md={12} lg={4}>
					<Form.Group className="mb-4" controlId="state_province_region">
						<Form.Label className="fs-5">State/Province/Region</Form.Label>
						<Form.Control
							required
							placeholder="State/Province/Region"
							className="py-3"
							name="state_province_region"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="state_province_region"
						field="state_province_region"
						errors={state.errors}
					/>
				</Col>
			</Row>

			<Form.Group className="mb-4" controlId="subject">
				<Form.Label className="fs-5">Subject</Form.Label>
				<Form.Select name="subject" className="py-3 ubuntu-regular text-p">
					<option className="ubuntu-regular text-p">Please select</option>
					<option className="ubuntu-regular text-p">Rehab</option>
					<option className="ubuntu-regular text-p">Injections</option>
				</Form.Select>
			</Form.Group>

			<ValidationError prefix="Subject" field="subject" errors={state.errors} />

			<Form.Group className="mb-4" controlId="which_side_is_facial_palsy">
				<Form.Label className="fs-5">Which side is facial palsy?</Form.Label>
				<Form.Select
					name="which_side_is_facial_palsy"
					className="py-3 ubuntu-regular text-p"
				>
					<option className="ubuntu-regular text-p">Please select</option>
					<option className="ubuntu-regular text-p">Left</option>
					<option className="ubuntu-regular text-p">Right</option>
					<option className="ubuntu-regular text-p">Both</option>
				</Form.Select>
			</Form.Group>
			<Form.Group className="mb-4" controlId="type_of_appointment_preferred">
				<Form.Label className="fs-5">Type of appointment preferred</Form.Label>
				<Form.Select
					name="type_of_appointment_preferred"
					className="py-3 ubuntu-regular text-p"
				>
					<option className="ubuntu-regular text-p">Please select</option>
					<option className="ubuntu-regular text-p">Face to face</option>
					<option className="ubuntu-regular text-p">Online</option>
				</Form.Select>
			</Form.Group>

			<ValidationError
				prefix="type_of_appointment_preferred"
				field="type_of_appointment_preferred"
				errors={state.errors}
			/>
			<ValidationError
				prefix="which_side_is_facial_palsy"
				field="which_side_is_facial_palsy"
				errors={state.errors}
			/>
			<Row className="gx-6">
				<Col className="mb-4" md={6}>
					<Form.Group controlId="date_of_onset">
						<Form.Label className="fs-5">Date of onset</Form.Label>
						<Form.Control className="py-3" type="date" name="date_of_onset" />
					</Form.Group>

					<ValidationError
						prefix="date_of_onset"
						field="date_of_onset"
						errors={state.errors}
					/>
				</Col>

				<Col className="mb-4" md={6}>
					<Form.Group controlId="diagnosis_if_know">
						<Form.Label className="fs-5">Diagnosis if know</Form.Label>
						<Form.Control
							placeholder="Diagnosis (if known)"
							className="py-3"
							name="diagnosis_if_know"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="diagnosis_if_know"
						field="diagnosis_if_know"
						errors={state.errors}
					/>
				</Col>
			</Row>
			{/* <Row className="gx-6">
				<Col className="">
					<Form.Group className="mb-4" controlId="preferred_therapist">
						<Form.Label className="fs-5">Preferred therapist</Form.Label>
						<Form.Select
							name="preferred_therapist"
							className="py-3 ubuntu-regular text-p"
						>
							<option className="ubuntu-regular text-p">No preference</option>
							<option className="ubuntu-regular text-p">Tamsin Gwynn</option>
							<option className="ubuntu-regular text-p">Karen Young</option>
							<option className="ubuntu-regular text-p">
								Catriona Neville
							</option>
						</Form.Select>
					</Form.Group>

					<ValidationError
						prefix="preferred_therapist"
						field="preferred_therapist"
						errors={state.errors}
					/>
				</Col>
			</Row> */}
			<Form.Group className="mb-4" controlId="details-of-enquiry">
				<Form.Label className="fs-5">Details of enquiry</Form.Label>
				<Form.Control
					placeholder="Input details of enquiry in here"
					className="py-3"
					name="details-of-enquiry"
					as="textarea"
					rows={5}
				/>
			</Form.Group>
			<ValidationError
				prefix="details-of-enquiry"
				field="details-of-enquiry"
				errors={state.errors}
			/>
			{/* <div
				class="g-recaptcha"
				data-sitekey="6LcauP0gAAAAAMl9A0wI1H7n2ZyRHqx2MpLoxl5Y"
			></div> */}

			<div className="text-end">
				<Button
					style={{ borderRadius: "0px" }}
					className="btn western w-100  white-link-blue px-5 mt-4 mt-md-0  py-2 "
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					Send message
				</Button>
			</div>
			<p className="mt-4">We endeavour to reply within 48 hours.</p>
			<p>Please make sure to check your junk mail for our reply.</p>
		</Form>
	);
}
export default ContactForm;
